@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html, body {
    height: 100%;
    overflow: auto;
  }

  #root {
    @apply grid grid-rows-[auto_1fr_auto] gap-4;

    height: 100%;
    overflow:auto;

    background-image: url('/public/background.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  a {
    display: inline-block;
  }
}
